export const Environment = {
            production: true,
            appVersion: require('../../package.json').version + '-prod',
            analysisAPIRoot: '//revops.revenup.ai',
            iEAPIRoot: '//revops.revenup.ai',
            OIAPIRoot: '//revops.revenup.ai',
            dataCollectionAPIRoot: 'revops.revenup.ai:444/api/dc-v1',
            revOperationAPIRoot: 'revops.revenup.ai/api/revops',
            utmAPIRootWithoutHyphen: 'revops.revenup.ai:442/api/utm-v1/',
            utmAPIRoot: 'revops.revenup.ai:442/api/utm-v1',
            taskMiningAPIRoot: '-be1.opxce.com/api/tmv1',
            IIAPIRoot: '//revops.revenup.ai',
            revOpsRoot: '//revops.revenup.ai/api/revops/',
            revenupRoot: '//revops.revenup.ai:446/api/revenup/',
            genAI: '//revops.revenup.ai:446/api/revenup/intel/'
          };